import * as Styles from "./styled/legalPage.styled";

import LegalMenu from "../components/common/LegalMenu";
import Modals from "../components/Modals";

import { useDesktop } from "../styled/useMedia";

import { logo } from "../data/common";
import LegalSections from "../components/Sections/Legal";


const RKLegalPage = () => {
  const isDesktop = useDesktop();

  return (
    <Styles.Wrapper>
      <Styles.Root>
        {isDesktop && <LegalMenu logo={logo} />}
        <Styles.Header>
          <Styles.Container>
            <Styles.StyledLink to="/">
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10 4L6 8L10 12"
                  stroke="white"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              Вернуться назад
            </Styles.StyledLink>
          </Styles.Container>
        </Styles.Header>
        <Styles.Main>
          <Styles.Item>
            <LegalSections />
          </Styles.Item>
        </Styles.Main>
        <Modals />
      </Styles.Root>
    </Styles.Wrapper>
  );
};

export default RKLegalPage;
